import {createRouter, createWebHistory} from "vue-router";

const scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        setTimeout(() => {
            const element = document.getElementById(to.hash.replace(/#/, ''))
            if (element && element.scrollIntoView) {
                element.scrollIntoView({block: 'end', behavior: 'smooth'})
            }
        }, 900)

        //NOTE: This doesn't work for Vue 3
        //return {selector: to.hash}

        //This does
        return {el: to.hash};
    } else if (savedPosition) {
        return savedPosition
    }
    return {top: 0}
}

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: scrollBehavior,
    routes: [
        {
            path: '/',
            name: "Home",
            component: () => import('../views/HomeView')
        },
        {
            path: '/service',
            name: "ServiceType",
            component: () => import('../views/ServiceType.vue')
        },
        {
            path: '/download-app',
            name: "DownloadApp",
            component: () => import('../views/DownloadApp.vue')
        },
        {
            path: '/comunity',
            name: "ComunityWeb",
            component: () => import('../views/ComunityWeb.vue')
        },
        {
            path: '/about',
            name: "AboutService",
            component: () => import('../views/AboutService.vue')
        },
        {
            path: '/agreement',
            name: "Agreement",
            component: () => import('../views/legal/Agreement.vue')
        },
        {
            path: '/offer',
            name: "Offer",
            component: () => import('../views/legal/Offer.vue')
        },
        {
            path: '/person',
            name: "Person",
            component: () => import('../views/legal/Person.vue')
        },
        {
            path: '/police',
            name: "Police",
            component: () => import('../views/legal/Police.vue')
        },
        {
            path: '/service-offer',
            name: "Service",
            component: () => import('../views/legal/Service.vue')
        },
        {
            path: '/standart',
            name: "Standart",
            component: () => import('../views/legal/Standart.vue')
        }
    ],
    // history: createWebHistory()
})

export default router
