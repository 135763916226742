import {createApp} from 'vue'
import App from '../js/components/App'
import router from '../js/components/router';
import store from '../js/components/store'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

require('./bootstrap');

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const app = createApp(App);
app.component('VueDatePicker', VueDatePicker);
app.use(router);
app.use(store);

const options = {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true
};
app.use(Toast, options);

app.mount('#app');
