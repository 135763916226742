import {createStore} from 'vuex'
import axios from 'axios';


const store = createStore({
    state: {
        prices: [],
        country: 1,
        booking: {
            type: 1,
            rooms: 1,
            bathrooms: 1,
            abonement: 0,
            //
            fridge: 0,
            window: 0,
            oven: 0,
            chandelier: 0,
            kitchen: 0,
            ironing: 0,
            microwave: 0,
            pet: 0,
            //
            address: '',
            addressKv: '',
            name: '',
            phone: '',
            date: null,
            time: null,
            latitude: null,
            longtitude: null
        },

    },
    mutations: {
        SET_BOOKING(state, booking) {
            state.booking = booking;
        },
        SET_PRICES(state) {
            try {
                let apiCountry = 'dome';
                if (state.country === 1) {
                    apiCountry = 'dome';
                } else {
                    apiCountry = 'domeMsk';
                }
                axios.get("https://mopup.ru/api/" + apiCountry)
                    .then(response => {
                        state.prices = response.data;
                    });

                // commit('SET_PRICES', data.data);
            } catch (error) {
                console.log(error)
            }
        },
        SET_COUNTRY(state, country) {
            state.country = country;
        }
    },
    actions: {
        GET_PRICES(context) {
            context.commit("SET_PRICES");
        },
        loadIbooking: (context, booking) => {
            context.commit('SET_BOOKING', booking);
        }
    },
    getters: {
        COUNTRY_VALUE: (state) => {
            let apiCountry = 'dome';
            if (state.country === 1) {
                apiCountry = 'dome';
            } else {
                apiCountry = 'domeMsk';
            }
            return apiCountry;
        },
        PRICES: state => {
            return state.prices;
        },
        BOOKING: state => {
            return state.booking;
        }
    },
});

export default store;
