<template>
    <NavBar></NavBar>

    <main class="overflow-hidden">
        <router-view/>
    </main>

    <FooterMain></FooterMain>
</template>

<script setup>
import {defineAsyncComponent} from 'vue'

const NavBar = defineAsyncComponent(() => import('./components/Navbar.vue'))
const FooterMain = defineAsyncComponent(() => import('./components/FooterMain.vue'))
</script>

<style scoped></style>
